import { MultipleReasons } from '@/util/core/MultipleReasons';
import { Howl, HowlOptions } from 'howler';

const REGULAR_MUTE_REASON = 'manually-muted';

export class VoiceoverService {
  public readonly reasonsToMute = new MultipleReasons();

  private readonly _howlInstance: Howl;

  constructor(public readonly howlOptions: HowlOptions) {
    this._howlInstance = new Howl(howlOptions);
    this.reasonsToMute.on({
      nonEmpty: () => this.stopCurrent(),
    });
  }

  setMuted(shouldMute: boolean) {
    if (this.isMuted === shouldMute) {
      return;
    }

    this.reasonsToMute.set(REGULAR_MUTE_REASON, shouldMute);
  }

  get isMuted() {
    return this.reasonsToMute.has(REGULAR_MUTE_REASON);
  }

  playVoiceLine(key: string): Promise<void> {
    if (this.reasonsToMute.hasAny()) {
      return Promise.resolve();
    }

    const sprite = this.howlOptions.sprite?.[key];
    if (!sprite) {
      console.warn('🎤 No sprite configuration found for:', key);
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const soundId = this._howlInstance.play(key);

      // const audioContext = Howler.ctx as AudioContext;
      // if (audioContext && audioContext.state !== 'running') {
      //   return Promise.reject(new Error('AudioContext is not running.'));
      // }

      if (soundId === null) {
        console.warn('🎤 Failed to play sound:', key);
        return resolve();
      } else {
        const [start, duration] = sprite; // Assuming sprite is an array as per Howler's typical use (e.g., [start, duration])
        console.log('🎤 Playing sound sprite:', soundId);

        // Additional logging for potential issues
        const totalDuration = this._howlInstance.duration() * 1000;
        if (start + duration > totalDuration) {
          console.error('🎤 Sprite duration exceeds audio length:', { key, start, duration, totalDuration });
          resolve();
        }

        // Logging all Howler events to see what happens
        this._howlInstance.on('end', id => {
          console.log('🎤 Sound ended:', id);
          if (id === soundId) {
            resolve();
          }
        });

        this._howlInstance.on('stop', id => {
          console.log('🎤 Sound stopped:', id);
          resolve();
        });

        this._howlInstance.on('playerror', (id, error) => {
          console.error(`🎤 Play error on sound ID ${id}:`, error);
          resolve();
        });

        this._howlInstance.on('loaderror', (id, error) => {
          console.error(`🎤 Load error on sound ID ${id}:`, error);
          resolve();
        });
      }
    });
  }

  hasVoiceLine(key: string): boolean {
    return this.howlOptions.sprite?.[key] !== undefined;
  }

  stopCurrent(): void {
    this._howlInstance.stop();
    this._howlInstance.off(); // Remove all event listeners
  }

  unload(): void {
    this._howlInstance.unload(); // Unload the Howl instance to free up memory
  }
}
